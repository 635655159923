<template>
    <div class="chat">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">银企对话</div>
       <div class="navHeader flex">
        <div class="search-box flex">          
          <input type="text" placeholder="请输入搜索关键词" v-model="searchVal" @keyup.enter="searchSubmit">
          <img src="@/assets/img/serch.png" @click="searchSubmit">
        </div>
      </div>
       <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad">
        <!-- <div class="card">
            <div class="nums">需求编号：10955</div>
            <div class="details">
                <div class="catalog">
                    <p>申请的机构</p>
                    <p>中国工商银行南宁支行</p>
                </div>
                <div class="catalog">
                    <p>留言数目</p>
                    <p>1</p>
                </div>
                <div class="catalog">
                    <p>最后留言时间</p>
                    <p>2021-10-08 19:45:04</p>
                </div>
            </div>
            <router-link to="/chatView" class="break">
            <van-icon name="chat-o" size="5vw"/>
            <p>留言/回复</p>
            </router-link>
            <img class="status" src="@/assets/img/chat_daishouli.png" alt="">
        </div> -->
        <div class="card" v-for="item in list" :key="item">
            <div class="nums">需求编号：{{item.requireId}}</div>
            <div class="details">
                <div class="catalog">
                    <p>申请的机构</p>
                    <p>{{item.bankName}}</p>
                </div>
                <div class="catalog">
                    <p>留言数目</p>
                    <p>{{item.num}}</p>
                </div>
                <div v-if="item.num > 0" class="catalog">
                    <p>最后留言时间</p>
                    <p>{{item.lastTime}}</p>
                </div>
            </div>
            <div @click="message(item.approvalId)" class="break">
            <van-icon name="chat-o" size="5vw"/>
            <p>留言/回复</p>
            </div>
            <!-- <img class="status" src="@/assets/img/chat_yizhongzhi.png" alt=""> -->
        </div>
    </van-list>
    </div>
</template>
<script>
import { ref } from 'vue';
import { apiGetDialogueList } from '../../api/axios';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
export default {
    setup() {
    let router = useRouter();
    let store = useStore();
    const list = ref([]);
    const totalPage = ref(0);
    const pageNo = ref(1);
    const loading = ref(false);
    const finished = ref(false);
    const message = (id) => {
        router.push({
            name: 'chatView',
            query: {
                approvalId: id
            }
        })
    };
        const onLoad = () => {
          apiGetDialogueList({pageNo: pageNo.value}).then(res => {                
             if(res.code == '1'){
                //  list.value = res.info.messagetList;
                res.info.messagetList.forEach(e => {
                    list.value.push(e)
                });              
                if( pageNo.value === 1) {
                  totalPage.value = res.info.page.totalPage;
                  finished.value = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(pageNo.value >= totalPage.value){
                  finished.value = true;
                }else {
                  pageNo.value++;
                  // 加载状态结束
                  loading.value = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
    //搜索框键入
        const searchVal = ref('');
        const searchSubmit = () => {
          console.log('搜索值为：',searchVal.value);
        }; 
    const goBack = () =>{
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.back.postMessage(null);
          } catch (e) {
            alert('跳转返回ios失败');
          }
        }else {
            router.push({
                name: 'User'
            })
        }
    }
    return {
      list,
      onLoad,
      loading,
      finished,
      searchVal,
      searchSubmit,
      message,
      goBack
    };
    },

}
</script>
<style lang="less" scoped>
.chat {
    padding-top: 12.5vw;    
    .card {
        position: relative;
        .nums {
            display: inline-block;
            color: #ff9900;
            border: 1px solid #ff9900;
            border-radius: 1vw;
            font-size: 4vw;
            padding: 0 2vw;
        }
        .details {
            width: 100%;
            font-size: 4vw;
            margin: 4vw 0;
            .catalog {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f5f5f5;
            p:first-child {
                width: 26vw;
                color: #666666;
                padding: 1.5vw 0;
            }
            p:last-child {
                width: 55vw;
                padding-left: 4vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            }
            
        }
        .break {
            display: inline-flex;
            align-items: center;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 2vw 3vw;
            border-radius: 6vw;
            p {
                margin-left: 1vw;
                line-height: 4vw;
            }
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
}
</style>